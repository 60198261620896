import React from "react"
import { graphql } from 'gatsby'

import Layout from '../../layout'

import BackgroundContainer from '../../components/image/background-container'
import TitleContainer from '../../components/common/title-container'
import Title from '../../components/common/title'
import Link from '../../components/common/link'
import PageContainer from '../../components/common/page-container'

export default (props) => (
  <Layout title="API Development">
    <BackgroundContainer header={props.data.header}>
      <TitleContainer>
        <Title>API Development</Title>
      </TitleContainer>
    </BackgroundContainer>
    <PageContainer>
      <p>APIs allow different systems to communicate with each other programatically.</p>
      <p>Sometimes, this might be a website communicating with a backend application, or a mobile app talking to an API. In other cases, it might be two behind the scenes systems communicating.</p>
      <p>We specialise in building robust, well tested, secure, scalable APIs that make it easy for different systems to talk to each other.</p>
      <ul>
        <li>Mobile apps use APIs to send and receive data, and keep user data in sync</li>
        <li>Modern websites and <Link href="/services/front-end-development">single-page-applications</Link> use APIs so that they can focus on rich user experiences.</li>
        <li>APIs can be developed as "middleware" to bridge two disconnected systems. We have built APIs to transform desktop software into collaborative software communicating via an API between two users, and to allow desktop software to communicate with cloud services.</li>
      </ul>
      <p>Because APIs are designed for automated, machine-to-machine communication, we build APIs using a test-first approach, ensuring that we have automated tests which validate that the API contains the appropriate business logic.</p>
      <p>Security is a big concern with API projects, we use industry standard authentication (OAuth 2.0) to manage authentication with APIs. This allows your users to authenticate with an API without having to disclose their password. That way, you can have third party developers build solutions which work with your API.</p>

    </PageContainer>
  </Layout>
)

export const query = graphql`
  query {
    header: file(relativePath: { eq: "api-development.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000, maxHeight: 1000, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
